import _ from "lodash";
import constants from "./constants";

const windowGlobal = typeof window !== "undefined" && window;

const ProvideType = {
  website: 1,
  qrcode: 2,
};

const ValidField = {
  cht: 101,
  hn: 102,
  md: 103,
  tel: 104,
  "tel-2": 105,
  phone: 106,
  email: 107,
  "secondary-email": 108,
  "facebook-id": 109,
  "serial-number": 110,
  "device-id": 111,
  "ex-1": 202,
  "ex-2": 203,
};
const LoginType = {
  1: "anonymous",
  2: "validation",
  3: "parameter",
  anonymous: 1,
  validation: 2,
  parameter: 3,
};

const duplicateMechanismType = {
  1: "overwrite",
  2: "first-only",
  3: "repeat",
  overwrite: 1,
  "first-only": 2,
  repeat: 3,
};

const defaultTheme = {
  theme: {
    web: {
      title: { color: "#0075d3", size: 28 },
      matrix: { color: "#324250", size: 14 },
      question: { color: "#324250", size: 20 },
      option: { color: "#324250", size: 16 },
      optionSeq: { color: "#209cff", size: 16 },
      note: { color: "#919191", size: 14 },
      hint: { color: "#209cff", size: 12 },
      reqire: { color: "#ff4d4f", size: 12 },
    },
    mobile: {
      title: { color: "#0075d3", size: 24 },
      matrix: { color: "#209cff", size: 18 },
      question: { color: "#495764", size: 20 },
      option: { color: "#364553", size: 16 },
      optionSeq: { color: "#209cff", size: 16 },
      note: { color: "#919191", size: 14 },
      hint: { color: "#209cff", size: 12 },
      reqire: { color: "#ff4d4f", size: 12 },
    },
  },
  background: [
    { id: 1, color: "#e2f2ff" },
    { id: 2, color: "#c5e5ff" },
  ],
  button: { primary: "#209cff", secondary: "#c5e5ff" },
};

async function fetchEx(
  url,
  options = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + windowGlobal.localStorage.getItem("token"),
    },
    // 2022-05-16: 新增問卷發生異常提示對話框
    // 新增useCatchAlert參數控制是需要顯示特殊對話框
    useCatchAlert: true,
  },
  signal
) {
  if (constants.useProxy) {
    const resp = await fetch(`${constants.proxyHost}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        //Authorization: authToken
      },
      body: JSON.stringify({
        url,
        ...options,
      }),
    });

    const json = await resp.json();
    return json;
  } else {
    if (!options.headers.Authorization) {
      options.headers.Authorization =
        "Bearer " + windowGlobal.localStorage.getItem("token");
    }
    let json = {};

    try {
      const resp = await fetch(`${url}`, {
        ...options,
        signal,
      })
        .then(response => {
          let json = {
            code: `${response.status}`,
            message: "似乎遇到錯誤...",
            data: {},
          };
          if (!response.ok) {
            json.status = response.status;
            return json;
          }
          try {
            json = response.json();
          } catch (ex) {
            /** 真的沒有就噴錯 */
            json = JSON.parse(response.text());
          }
          return json;
        })
        .catch(ex => {
          // 發生錯誤，回應預設錯誤
          return { code: "400", message: "似乎遇到錯誤...", data: {} };
        });

      json = resp;
      const status = _.get(json, "status", 400);
      const code = _.get(json, "code", "400");
      if (status === 401) {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("verifyInfo");
        window.localStorage.setItem("expired", true);
        // window.alert("您使用的是無效Token，系統將自動為您登出。");
        window.location.replace("/");
        // window.location.reload();
        return;
      }

      if (code === "-999") {
        const profile = JSON.parse(
          windowGlobal.localStorage.getItem("profile")
        );
        if (profile == null) return;

        var userAccount = profile.UserCode;
        var token = windowGlobal.localStorage.token;
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("profile");
        window.alert("資料權限檢核未通過，系統將自動為您登出。");

        const API = new ApiAdapter();
        let response = API.logOutWithToken(userAccount, token);
        Promise.resolve(response).then(
          function(res) {
            if (res.code === "200") {
              window.localStorage.removeItem("token");
              window.localStorage.removeItem("profile");
              // window.location.reload();
            }
          },
          function(res) {
            alert(res.message);
          }
        );
      }

      if (code === "406") {
        windowGlobal.localStorage.removeItem("token");
        windowGlobal.localStorage.removeItem("profile");
      }
    } catch (ex) {
      // 2022-05-16: 新增問卷發生異常提示對話框
      // 新增useCatchAlert參數控制是需要顯示特殊對話框
      if (options.useCatchAlert) alert("系統異常，請洽系統管理員");
      else json = { code: "400", message: "似乎遇到錯誤...", data: {} };
    }

    return json;
  }
}

function sortByQuestionPageAndSeq(a, b) {
  a = { ...a };
  b = { ...b };

  if (a.PageNo === null) {
    a.PageNo = 0;
  }

  if (b.PageNo === null) {
    b.PageNo = 0;
  }

  if (a.PageNo > b.PageNo) {
    return 1;
  } else if (a.PageNo < b.PageNo) {
    return -1;
  } else {
    if (a.QuestionSeq > b.QuestionSeq) {
      return 1;
    } else if (a.QuestionSeq < b.QuestionSeq) {
      return -1;
    }
    return 0;
  }
}

function sortByOptionSeq(a, b) {
  if (a.OptionSeq > b.OptionSeq) {
    return 1;
  } else if (a.OptionSeq === b.OptionSeq) {
    return 0;
  }
  return -1;
}

function mapToQuestionContent(subject) {
  if (!subject) {
    return JSON.parse('[{"type":"paragraph","children":[{"text":"題目"}]}]');
  } else {
    try {
      return JSON.parse(subject);
    } catch (ex) {
      return [
        {
          type: "paragraph",
          children: [{ text: subject }],
        },
      ];
    }
  }
}

function mapToQuestionInfoType(vType) {
  // from backend:
  // 1	HN編號
  // 2	MD編號
  // 3	身分證字號
  // 4	手機號碼
  // 5	Email
  // -> to frontend:
  // hn: "HN12456",
  // md: "MD12345678",
  // id: "F123456789",
  // phone: "10個號碼",
  // email: "survey@cht.com",
  switch (vType) {
    case 1:
      return "hn";
    case 2:
      return "md";
    case 3:
      return "id";
    case 4:
      return "phone";
    case 5:
      return "email";
    default:
      return "email";
  }
}

function mapBasicInfoValidTypeToQuestionData(infoType) {
  // from frontend:
  // hn: "HN12456",
  // md: "MD12345678",
  // id: "F123456789",
  // phone: "10個號碼",
  // email: "survey@cht.com",
  // -> to backend:
  // 1	HN編號
  // 2	MD編號
  // 3	身分證字號
  // 4	手機號碼
  // 5	Email

  switch (infoType) {
    case "hn":
      return 1;
    case "md":
      return 2;
    case "id":
      return 3;
    case "phone":
      return 4;
    case "email":
      return 5;
    default:
      return 1;
  }
}

function mapToQuestionValidateAnswerObj(q) {
  const typeValueMap = {
    // 1 無驗證
    // 2 驗證文字字數
    // 3 驗證數字整數
    1: "none",
    2: "text",
    3: "number",
  };
  return {
    type: typeValueMap[q.BlankValidType] || "none",
    from: q.BlankMinLimit ? q.BlankMinLimit : q.BlankValidType === 2 ? 1 : 0,
    to: q.BlankMaxLimit ? q.BlankMaxLimit : 100,
  };
}

function mapToQuestionCols(MatrixItems) {
  try {
    return MatrixItems.split(";").filter(v => !!v && v !== " " && v !== "");
  } catch (ex) {
    return [];
  }
}

function mapToQuestionRows(options) {
  try {
    return options.map(opt => opt.OptionContent);
  } catch (ex) {
    return [];
  }
}

function mapToQuestionOtherOption(q, otherOption) {
  const {
    HasOther,
    OtherIsShowText,
    OtherVerify,
    // OtherMandatory,
    OtherTextMandatory,
    OtherCheckMessage,
    UseOtherText,
    OtherText,
  } = q;
  // "OtherIsShowText": true,
  // "OtherVerify": 1, // 0 or 1 是否驗證
  // "OtherMandatory": opt.data.isMandatory,
  // "OtherCheckMessage": opt.data.errorMsg
  if (HasOther) {
    return [
      {
        id: otherOption.OptionId,
        value: otherOption.OptionContent,
        type: "others",
        connectTo: otherOption.ChildQuestionId || undefined,
        data:
          OtherIsShowText || UseOtherText
            ? {
                // isMandatory: OtherMandatory,
                otherIsShowText: OtherIsShowText,
                isMandatory: OtherTextMandatory,
                errorMsg: OtherCheckMessage,
                validateType:
                  OtherVerify === 1
                    ? "none"
                    : OtherVerify === 2
                    ? "length"
                    : "number",
                from: q.OtherMinLimit,
                to: q.OtherMaxLimit,
                // 是否使用在「其他」之後增加說明文字
                useOtherText: UseOtherText,
                // 在「其他」之後增加說明文字
                otherText: OtherText,
              }
            : null,
      },
    ];
  }

  return [];
}

function mapToQuestionOptions(options) {
  return options.map(opt => {
    const {
      OptionId,
      OptionType,
      OptionContent,
      OptionImage,
      OptionVideo,
      ChildQuestionId,

      // 數字總和題
      OptionMinLimit,
      OptionMaxLimit,
    } = opt;

    const out = { id: OptionId };
    out.value = OptionContent;

    if (OptionImage || OptionVideo) {
      out.url = OptionImage || OptionVideo;
      out.value = OptionContent;

      if (OptionImage) {
        out.mediaType = "image";
      } else if (OptionVideo) {
        out.mediaType = "video";
      }
    }

    if (ChildQuestionId) {
      out.connectTo = ChildQuestionId;
    }

    // 數字總和題
    if (OptionMinLimit != null) {
      out.optionMinLimit = OptionMinLimit;
    }
    if (OptionMaxLimit != null) {
      out.optionMaxLimit = OptionMaxLimit;
    }

    return out;
  });
}

function mapToQuestionAdvancedObj(q, isMultipleChoiceQuestion) {
  const {
    HasOther,
    OtherIsShowText,
    OtherVerify,
    OtherTextMandatory,
    OtherCheckMessage,
    IsSetShowNum,
    PCRowNum,
    MobileRowNum,
    IsRamdomOption,
    ExcludeOther,
    MultiOptionLimit,
  } = q;

  let result = {
    display: IsSetShowNum
      ? { desktop: PCRowNum || 1, mobile: MobileRowNum || 1 }
      : null,
    random: IsRamdomOption ? { excludeOthers: ExcludeOther } : null,
  };

  if (isMultipleChoiceQuestion) {
    if (!MultiOptionLimit) {
      result.limit = null;
    } else {
      try {
        let [from, to] = MultiOptionLimit.split(":");
        result.limit = {
          from,
          to,
        };
      } catch (err) {
        result.limit = null;
      }
    }
  }

  return result;
}

function mapToQuestion(q) {
  let result = {};

  if (q.type === "PAGE") {
    return q; // the 1st, added by frontend
  }

  result.id = q.QuestionId;
  if (q.QuestionType === 1) {
    result.type = "SINGLE_CHOICE";
  } else if (q.QuestionType === 2) {
    result.type = "MULTIPLE_CHOICE";
  } else if (q.QuestionType === 3) {
    result.type = "FILL_IN";
  } else if (q.QuestionType === 4) {
    result.type = "MATRIX";
  } else if (q.QuestionType === 5) {
    result.type = "BASIC_INFO";
  } else if (q.QuestionType === 6) {
    result.type = "NUMERICAL";
  } else if (q.QuestionType === 10) {
    result.type = "TEXT";
  } else if (q.QuestionType === 11) {
    result.type = "IMAGE";
  } else if (q.QuestionType === 12) {
    result.type = "VIDEO";
  } else if (q.QuestionType === 13) {
    result.type = "PAGE";
  }

  // feng 20211008 hotfix
  if (q.QuestionType === 5 && !q.QuestionSubject) {
    q.QuestionSubject = "Email";
  }
  result.required = q.IsRequired;
  result.content = mapToQuestionContent(q.QuestionSubject);
  result.contentImage = q.QuestionImage;
  result.contentVideo = q.QuestionVideo;
  result.description = q.QuestionNote;
  result.isMandatory = q.OtherTextMandatory;
  result.infoType = mapToQuestionInfoType(q.BaseDataValidType);
  result.answerPlaceholder = q.BlankDefaultWords;
  result.validateAnswer = mapToQuestionValidateAnswerObj(q);
  result.advanced = mapToQuestionAdvancedObj(
    q,
    result.type === "MULTIPLE_CHOICE"
  );
  if (result.type === "MATRIX") {
    result.cols = mapToQuestionRows(q.option?.sort(sortByOptionSeq) || []);
    result.rows = mapToQuestionCols(q.MatrixItems);
  }
  // if (result.type === "MATRIX") {
  //   result.cols = mapToQuestionCols(q.MatrixItems);
  //   result.rows = mapToQuestionRows(q.option?.sort(sortByOptionSeq) || []);
  // }
  if (result.type === "VIDEO") {
    result.url = q.SubjectStyle;
  }
  if (result.type === "IMAGE") {
    result.url = q.SubjectStyle;
  }
  let otherOption = null;
  if (q.HasOther) {
    otherOption = q.option.pop();
  }
  result.options = [
    ...mapToQuestionOptions(
      Array.isArray(q.option) ? q.option.sort(sortByOptionSeq) : []
    ),
    ...mapToQuestionOtherOption(q, otherOption),
  ];
  result.PageNo = q.PageNo || 0; // note: frontend will not use this field

  // 數字總和題
  if (q.QuestionType === 6) {
    result.numericalStyle = q?.NumericalStyle;
    result.numericalTotal = q?.NumericalTotal;
    result.decimalPlace = q?.DecimalPlace;
    result.unit = q?.Unit;
  }
  result._raw = { ...q };
  return result;
}

function mapToSurveyStatus(s) {
  return s.Status; // note: frontend "1" 對應建立中
}

function mapToSurvey(data) {
  const r = {};
  r.id = data.SurveyId;
  r.title = data.Title;
  r.audit = !!data.Audit;
  r.isBlank = !!data.IsEmpty;
  r.status = mapToSurveyStatus(data);
  r.responseCount = data.ReplyNum;
  r.author = data.CreateUserName;
  r.modified = data.UpdDateTime;
  r.startDate = data.StartDate;
  r.endDate = data.EndDate;
  r.teamId = data.TeamId;
  r.type = _.get(data, "Type");
  r.cdpValidRegister = _.get(data, "CDPValidRegister");
  r.cdpStartTime = _.get(data, "CDPStartTime");
  r.cdpEndTime = _.get(data, "CDPEndTime");
  r.cdpStatusCode = _.get(data, "CDPStatusCode");
  r.cdpSurveyStatus = _.get(data, "CDPSurveyStatus");
  return r;
}

function mapToCollectionList(data) {
  const r = {};
  r.key = data.SurveyId;
  r.method = data.ProvideType; //收集方式
  r.created = data.CreateDateTime; //建立時間
  r.updated = data.UpdDateTime; //最後修改時間
  r.respCnt = data.ReplyNum; //填寫次數
  r.upperBound = data.ReplyMaxNum; //問卷填寫上限
  r.accessList = data.accessList; //存取名單
  r.liveUrl = data.FinalUrl; //正式網址
  r.testUrl = data.TestUrl; //測試網址
  r.validateField = data.ValidField; //驗證欄位
  r.duplicateMechanism = duplicateMechanismType[data.MultiProvideType]; //重複填寫問券規則
  r.loginType = LoginType[data.ValidRegister]; //問卷登入方式
  r.limitType = data.LimitType; //問卷名額上限 類別
  return r;
}

function mapToAccessList(data) {
  const r = {};
  r.display = data;
  r.id = data;
  return r;
}

function mapToSurveyExtraProps({ configProps, themeProps }) {
  function mapToProgressDisplayDetail(props) {
    return {
      position: props.PorgressPosition === 0 ? "bottom" : "top", // FIXME: what's "Porgress"?
      display: props.ProgressStyle === 0 ? "percentage" : "questions",
    };
  }

  if (Array.isArray(configProps)) {
    // FIXME: why it is array!?
    configProps = {};
  }

  if (Array.isArray(themeProps)) {
    // FIXME: why it is array!?
    themeProps = {};
  }

  const extra = {};

  extra.pageIdxDisplay = configProps.IsShowPageNo !== "0";
  extra.questionIdxDisplay = configProps.IsShowQuestionNo !== "0";
  extra.optionIdxDisplay = configProps.IsShowOptionNo !== "0";
  extra.asteriskDisplay = configProps.IsShowRequiredStar !== "0";
  extra.progressDisplay = configProps.IsShowProgress !== "0";
  extra.progressDisplayDetail = mapToProgressDisplayDetail(configProps);
  extra.validationCode = configProps.UseVirifyCode !== "0";
  extra.oneQuestionPerPage = configProps.IsOneQuestionPerPage !== "0";
  extra.endingDisplay = configProps.IsShowEndPage !== "0";
  extra.welcomeDisplay = configProps.IsShowWelcomePage !== "0";

  extra.themeConfig = {
    backgroundColor: themeProps.DefBackgroudColor,
    headerImg:
      themeProps.StyleType === 1 || themeProps.StyleType === null
        ? null
        : themeProps.DefHeaderPic,
    mobileHeaderImg:
      themeProps.StyleType === 1 || themeProps.StyleType === null
        ? null
        : themeProps.DefHeaderPhonePic,
    styleType:
      themeProps.StyleType === 1 || themeProps.StyleType === null
        ? "default"
        : "customized",

    backgroundTheme: themeProps.DefTheme,
    headFlag: themeProps.HeadFlag,
    fontStyle: themeProps.DefFontStyle,
    Font: themeProps.Font,
  };

  return extra;
}

function insertPageIntoQuestions(questions) {
  return [{ id: "0", type: "PAGE" }, ...questions];
}

function getOtherObjData(q) {
  const defaultOther = {
    HasOther: false,
    OtherIsShowText: false,
    OtherVerify: 1, // 1 (none) or 2 (length) or 3 (number) 是否驗證
    OtherMandatory: false,
    OtherCheckMessage: "",
    OtherChildQuestionId: "",
    UseOtherText: false,
    OtherText: "",
  };
  let otherData = defaultOther;
  let otherOpts = Array.isArray(q.options)
    ? q.options.filter(o => o.type === "others")
    : [];
  if (otherOpts.length > 0) {
    otherData.HasOther = true;
    otherData.OtherChildQuestionId = otherOpts[0].connectTo;
    let opt = otherOpts[0];

    if (opt.data) {
      otherData = {
        ...otherData,
        OptionId: opt.id,
        OtherIsShowText: opt.data.otherIsShowText,
        OtherVerify:
          opt.data.validateType === "none"
            ? 1
            : opt.data.validateType === "length"
            ? 2
            : 3,
        OtherMandatory: opt.data.isMandatory,
        OtherCheckMessage: opt.data.errorMsg,
        OtherMinLimit: opt.data.from,
        OtherMaxLimit: opt.data.to,
        // 2022-05-18: 新增"其他"文字
        OptionContent: opt.value, // 前端固定帶回【其他】
        UseOtherText: opt.data.useOtherText, // 是否使用在「其他」之後增加說明文字
        OtherText: opt.data.otherText, // 在「其他」之後增加說明文字
      };
    }
  }

  return otherData;
}

function mergeUpdatedOptions({ ours, theirs, qType }) {
  const _otherOptionData = o => {
    if (o.mediaType === "image") {
      return {
        OptionImage: o.url,
        OptionVideo: "",
      };
    } else if (o.mediaType === "video") {
      return {
        OptionVideo: o.url,
        OptionImage: "",
      };
    }
    return {};
  };
  const out = [];

  console.log("ours =", ours);
  console.log("theirs =", theirs);

  for (let i = 0; i < ours.length; i++) {
    const ourOpt = ours[i];

    console.log("ourOpt", ourOpt);

    /**
     * hotfix 2021-01-09 插入中間選項時ID沒有更新的問題
     * let theirOpt = {}
     * try {
     * theirOpt = theirs[i];
     * } catch (err) {}
     */
    let theirOpt = _.find(theirs, o => {
      return _.has(ourOpt, "id") && ourOpt.id === _.get(o, "OptionId");
    });
    if (ourOpt.type === "others") {
      //
    } else {
      out.push({
        // OptionId: (theirOpt && theirOpt.OptionId) || null,
        // fix 如果原始對不到 theirOpt ，就給後端 null，後端接收null，表示選項為新增
        OptionId: theirOpt ? ourOpt.id : null,
        OptionSeq: i + 1,
        OptionType: 1, // note: always be 1
        OptionContent: ourOpt.value,
        OptionMinLimit: ourOpt.optionMinLimit,
        OptionMaxLimit: ourOpt.optionMaxLimit,
        ..._otherOptionData(ourOpt),
        ...(ourOpt.connectTo ? { ChildQuestionId: ourOpt.connectTo } : {}),
      });
    }
  }
  return out;
}

function mapAdvanceToQuestionData(q) {
  let { display, random, limit } = q.advanced;
  let result = {
    ShowWay: {
      IsSetShowNum: !!display,
      PCRowNum: display?.desktop || 1,
      MobileRowNum: display?.mobile || 1,
    },
    Random: {
      IsRamdomOption: !!random,
      ExcludeOther: random?.excludeOthers || false,
    },
  };

  if (limit) {
    result.MultiOptionLimit = `${limit.from || 1}:${limit.to}`;
  } else {
    result.MultiOptionLimit = "";
  }

  return result;
}

function mapAccountList(data) {
  data.map(u => {
    u.id = u.id;
    u.userid = u.UserId;
    u.name = u.UserName;
    u.username = u.UserCode;
    u.created = u.CreateDateTime;
    u.updated = u.UpdateDateTime;
    u.roleId = u.RoleId;
    u.role = u.RoleName;
    u.lastLoginAt = u.LastLogInDateTime;
    u.status = u.Status;
    u.from = u.UserFrom;
    u.phone = u.Telephone;
  });
}

// 個資map
function mapToPrivacy(data) {
  const r = {};
  r.id = data.PrivacyId;
  r.title = data.Title;
  r.content = data.Content;
  r.enable = data.Enable;
  r.version = data.Version;
  r.publishDateTime = data.PublishDateTime;
  r.updDateTime = data.UpdDateTime;
  r.createDateTime = data.CreateDateTime;
  return r;
}
function mapTeamList(data) {
  _.map(data, t => {
    t.teamid = t.TeamId;
    t.name = t.TeamName;
    t.owner = t.OwnerUserName;
    t.account = t.OwnerUserCode;
    t.created = t.CreateDateTime;
    t.updated = t.UpdateDateTime;
  });
}
function mapTeamUserList(data) {
  _.map(data, t => {
    t.userid = t.UserId;
    t.name = t.UserName;
    t.username = t.UserCode;
    t.teamroleid = t.RoleId;
    t.rolename = t.RoleName;
  });
}
class ApiAdapter {
  constructor() {
    if (process.env.NODE_ENV === "non-token") {
      this.host = constants.apiUrl;
    } else {
      this.host = constants.apiTokenUrl;
    }
  }

  async getOTPVerify(account, password) {
    let resp = await fetchEx(`${this.host}/api/SendOTP`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + windowGlobal.localStorage.token,
      },
      body: JSON.stringify({
        UserCode: account,
        pwd: password,
      }),
    });
    return resp;
  }
  async checkOTPVerify(account, password, verifyCode) {
    let resp = await fetchEx(`${this.host}/api/VerifyOTP`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + windowGlobal.localStorage.token,
      },
      body: JSON.stringify({
        VertifyCode: verifyCode,
        UserCode: account,
        CellPhone: password,
      }),
    });

    return resp;
  }

  async ssoVerify(uid) {
    let resp = await fetchEx(`${this.host}/api/system/auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        UserId: uid,
      }),
    });

    return resp;
  }
  /**
   * 問卷CDP SSOToken 驗證
   * @param {*} ssoToken
   * @returns
   */
  async getSsoToken(ssoToken) {
    const response = await fetchEx(`${this.host}/api/v1/mgt/auth/cdp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        channel: "survey",
        cdp_sso_token: ssoToken,
      }),
    });

    return response;
  }

  async fetchSurveyStatus() {
    if (
      typeof windowGlobal.localStorage.getItem("token") == "undefined" ||
      windowGlobal.localStorage.getItem("token") == null
    ) {
      // window.localStorage.removeItem("token");
      // window.localStorage.removeItem("verifyInfo");
      // window.alert("您使用的是無效Token，系統將自動為您登出。");
      // window.location.reload();
      return;
    }

    const json = await fetchEx(`${this.host}/api/Survey/Status/List`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
    });
    let resp = null;
    resp = json && json.data && json.data;
    return resp;
  }
  async querySurveyListForce(token) {
    if (
      typeof windowGlobal.localStorage.getItem("token") == "undefined" ||
      windowGlobal.localStorage.getItem("token") == null
    ) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("verifyInfo");
      window.alert("您使用的是無效Token，系統將自動為您登出。");
      window.location.replace("/");
      return;
    }
    let url = `${this.host}/api/Survey/Info/queryByPage?all=true`;
    const json = await fetchEx(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
    });

    let resp = null;
    if (json && json.data) {
      resp = json.data.map(mapToSurvey);
    }
    return resp;
  }
  async querySurveyList(
    token,
    page = 0,
    perPage = 10,
    queryInfo = null,
    search = null
  ) {
    if (
      typeof windowGlobal.localStorage.getItem("token") == "undefined" ||
      windowGlobal.localStorage.getItem("token") == null
    ) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("verifyInfo");
      window.alert("您使用的是無效Token，系統將自動為您登出。");
      window.location.replace("/");
      return;
    }
    let url = `${this.host}/api/Survey/Info/queryByPage`;
    let connector = url.indexOf("?") >= 0 ? "&" : "?";
    if (page !== 0) {
      url += `${connector}page=${page}`;
    }

    connector = url.indexOf("?") >= 0 ? "&" : "?";
    if (perPage !== 0) {
      url += `${connector}perPage=${perPage}`;
    }

    if (search != null && search.length > 0) {
      connector = url.indexOf("?") >= 0 ? "&" : "?";
      url += `${connector}search=${search}`;
    }
    if (queryInfo != null) {
      const keys = Object.keys(queryInfo);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        console.log(keys, queryInfo[key]);
        if (queryInfo[key] === undefined || queryInfo[key] === null) continue;

        let connector = url.indexOf("?") >= 0 ? "&" : "?";
        url += `${connector}${key}=${queryInfo[key]}`;
      }
    }
    const json = await fetchEx(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
    });

    let resp = null;
    if (json && json.data) {
      resp = json.data.map(mapToSurvey);
    }
    return {
      data: resp,
      pagination: json.pagination,
      filters: json.filters,
    };
  }

  async queryTeamSurveyList(
    token,
    teamId,
    page = 0,
    perPage = 10,
    queryInfo = null,
    search = null
  ) {
    if (
      typeof windowGlobal.localStorage.getItem("token") == "undefined" ||
      windowGlobal.localStorage.getItem("token") == null
    ) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("verifyInfo");
      window.alert("您使用的是無效Token，系統將自動為您登出。");
      window.location.replace("/");
      return;
    }

    let url = `${this.host}/api/Survey/Info/queryByPage?teamId=${teamId}`;
    let connector = url.indexOf("?") >= 0 ? "&" : "?";
    if (page !== 0) {
      url += `${connector}page=${page}`;
    }

    connector = url.indexOf("?") >= 0 ? "&" : "?";
    if (perPage !== 0) {
      url += `${connector}perPage=${perPage}`;
    }

    if (search != null && search.length > 0) {
      connector = url.indexOf("?") >= 0 ? "&" : "?";
      url += `${connector}search=${search}`;
    }
    if (queryInfo != null) {
      const keys = Object.keys(queryInfo);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        console.log(keys, queryInfo[key]);
        if (queryInfo[key] === undefined || queryInfo[key] === null) continue;

        let connector = url.indexOf("?") >= 0 ? "&" : "?";
        url += `${connector}${key}=${queryInfo[key]}`;
      }
    }
    const json = await fetchEx(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
    });

    let resp = null;
    if (json && json.data) {
      resp = json.data.map(mapToSurvey);
    }
    return {
      data: resp,
      pagination: json.pagination,
      filters: json.filters,
    };
  }

  async queryMyOwnSurveyList(
    token,
    page = 0,
    perPage = 10,
    queryInfo = null,
    search = null
  ) {
    if (
      typeof windowGlobal.localStorage.getItem("token") == "undefined" ||
      windowGlobal.localStorage.getItem("token") == null
    ) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("verifyInfo");
      window.alert("您使用的是無效Token，系統將自動為您登出。");
      window.location.replace("/");
      return;
    }

    let url = `${this.host}/api/Survey/Info/queryByPage?category=my`;
    let connector = url.indexOf("?") >= 0 ? "&" : "?";
    if (page !== 0) {
      url += `${connector}page=${page}`;
    }

    connector = url.indexOf("?") >= 0 ? "&" : "?";
    if (perPage !== 0) {
      url += `${connector}perPage=${perPage}`;
    }

    if (search != null && search.length > 0) {
      connector = url.indexOf("?") >= 0 ? "&" : "?";
      url += `${connector}search=${search}`;
    }
    if (queryInfo != null) {
      const keys = Object.keys(queryInfo);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        console.log(keys, queryInfo[key]);
        if (queryInfo[key] === undefined || queryInfo[key] === null) continue;

        let connector = url.indexOf("?") >= 0 ? "&" : "?";
        url += `${connector}${key}=${queryInfo[key]}`;
      }
    }

    const json = await fetchEx(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
    });

    let resp = null;
    if (json && json.data) {
      resp = json.data.map(mapToSurvey);
    }

    return {
      data: resp,
      pagination: json.pagination,
      filters: json.filters,
    };
  }

  async querySharedSurveyList(
    token,
    page = 0,
    perPage = 10,
    queryInfo = null,
    search = null
  ) {
    if (
      typeof windowGlobal.localStorage.getItem("token") == "undefined" ||
      windowGlobal.localStorage.getItem("token") == null
    ) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("verifyInfo");
      window.alert("您使用的是無效Token，系統將自動為您登出。");
      window.location.replace("/");
      return;
    }

    let url = `${this.host}/api/Survey/Info/queryByPage?category=shared`;
    let connector = url.indexOf("?") >= 0 ? "&" : "?";
    if (page !== 0) {
      url += `${connector}page=${page}`;
    }

    connector = url.indexOf("?") >= 0 ? "&" : "?";
    if (perPage !== 0) {
      url += `${connector}perPage=${perPage}`;
    }

    if (search != null && search.length > 0) {
      connector = url.indexOf("?") >= 0 ? "&" : "?";
      url += `${connector}search=${search}`;
    }
    if (queryInfo != null) {
      const keys = Object.keys(queryInfo);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        console.log(keys, queryInfo[key]);
        if (queryInfo[key] === undefined || queryInfo[key] === null) continue;

        let connector = url.indexOf("?") >= 0 ? "&" : "?";
        url += `${connector}${key}=${queryInfo[key]}`;
      }
    }
    const json = await fetchEx(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
    });

    let resp = null;
    if (json && json.data) {
      resp = json.data.map(mapToSurvey);
    }
    return {
      data: resp,
      pagination: json.pagination,
      filters: json.filters,
    };
  }

  async querySurveyById(id, OtherFlag) {
    if (
      typeof windowGlobal.localStorage.getItem("token") == "undefined" ||
      windowGlobal.localStorage.getItem("token") == null
    ) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("verifyInfo");
      window.alert("您使用的是無效Token，系統將自動為您登出。");
      window.location.replace("/");
      return;
    }

    let resp = null;
    let configResp = null,
      themeResp = null;

    resp = await fetchEx(
      `${this.host}/api/Survey/Detail/QueryByPage?surveyId=${id}&OtherFlag=${OtherFlag}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );

    if (
      typeof windowGlobal.localStorage.getItem("token") == "undefined" ||
      windowGlobal.localStorage.getItem("token") == null
    ) {
      return;
    }

    configResp = await fetchEx(
      `${this.host}/api/Survey/BaseConfig/Query?surveyId=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );

    if (
      typeof windowGlobal.localStorage.getItem("token") == "undefined" ||
      windowGlobal.localStorage.getItem("token") == null
    ) {
      return;
    }
    themeResp = await fetchEx(
      `${this.host}/api/Survey/Style/Query?surveyId=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );
    // 前端處理:Theme為null的情況，補上預設樣式
    if (!_.get(themeResp, "data.Theme")) {
      _.set(themeResp, "data.Theme", defaultTheme);
    }
    if (
      typeof windowGlobal.localStorage.getItem("token") == "undefined" ||
      windowGlobal.localStorage.getItem("token") == null
    ) {
      return;
    }
    if (Array.isArray(configResp.data)) {
      // note: blank resp, create config first
      configResp = await fetchEx(`${this.host}/api/Survey/BaseConfig/Insert`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + windowGlobal.localStorage.token,
        },
        body: JSON.stringify({
          SurveyId: id,
          IsShowRequiredStar: "1",
          IsShowEndPage: "1",
          IsShowWelcomePage: "1",
        }),
      });
    }
    let questions = resp.data.QuestionList
      ? resp.data.QuestionList.filter(q => !!q.QuestionId).sort(
          sortByQuestionPageAndSeq
        )
      : [];
    questions = insertPageIntoQuestions(questions).map(mapToQuestion);
    let extraProps = mapToSurveyExtraProps({
      configProps: configResp?.data,
      themeProps: themeResp?.data,
    });

    // CDP問卷多回應問卷類型
    return {
      ...mapToSurvey(resp.data),
      id: resp.data.SurveyId,
      audit: resp.data.Audit,
      title: resp.data.Title,
      upDateTime: resp.data.UpdDateTime,
      questions,
      ...extraProps,
      _configProps: configResp?.data,
      _themeProps: themeResp?.data,
    };
  }

  async querySurveyForCopyById(id, OtherFlag) {
    let resp = null;
    resp = await fetchEx(
      `${this.host}/api/Survey/Detail/QueryByPage?surveyId=${id}&OtherFlag=${OtherFlag}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );
    return resp;
  }

  async querySurveyNoPicById(id, OtherFlag) {
    let resp = null;
    let configResp = null,
      themeResp = null;

    resp = await fetchEx(
      `${this.host}/api/Survey/Detail/QueryNoPic?surveyId=${id}&OtherFlag=${OtherFlag}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );
    configResp = await fetchEx(
      `${this.host}/api/Survey/BaseConfig/Query?surveyId=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );
    themeResp = await fetchEx(
      `${this.host}/api/Survey/Style/Query?surveyId=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );
    // 前端處理:Theme為null的情況，補上預設樣式
    if (!_.get(themeResp, "data.Theme")) {
      _.set(themeResp, "data.Theme", defaultTheme);
    }
    if (Array.isArray(configResp.data)) {
      // note: blank resp, create config first
      configResp = await fetchEx(`${this.host}/api/Survey/BaseConfig/Insert`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + windowGlobal.localStorage.token,
        },
        body: JSON.stringify({
          SurveyId: id,
          IsShowRequiredStar: "1",
          IsShowEndPage: "1",
          IsShowWelcomePage: "1",
        }),
      });
    }
    let questions = resp.data.QuestionList
      ? resp.data.QuestionList.filter(q => !!q.QuestionId).sort(
          sortByQuestionPageAndSeq
        )
      : [];
    questions = insertPageIntoQuestions(questions).map(mapToQuestion);
    let extraProps = mapToSurveyExtraProps({
      configProps: configResp?.data,
      themeProps: themeResp?.data,
    });

    return {
      id: resp.data.SurveyId,
      title: resp.data.Title,
      audit: resp.data.Audit,
      upDateTime: resp.data.UpdDateTime,
      questions,
      ...extraProps,
      _configProps: configResp?.data,
      _themeProps: themeResp?.data,
    };
  }

  async updatePropertyById(id, props) {
    const propsMap = {
      pageIdxDisplay: "IsShowPageNo",
      optionIdxDisplay: "IsShowOptionNo",
      questionIdxDisplay: "IsShowQuestionNo",
      asteriskDisplay: "IsShowRequiredStar",
      progressDisplay: "IsShowProgress",
      validationCode: "UseVirifyCode",
      oneQuestionPerPage: "IsOneQuestionPerPage",
      endingDisplay: "IsShowEndPage",
      welcomeDisplay: "IsShowWelcomePage",
    };

    const out = {};
    let empty = true;

    for (const key in props) {
      const target = propsMap[key];
      const value = props[key];

      if (target) {
        out[target] = value ? "1" : "0";
        empty = false;
      } else if (key === "progressDisplayDetail") {
        out.PorgressPosition = value.position === "bottom" ? "0" : "1";
        out.ProgressStyle = value.display === "percentage" ? "0" : "1";
        empty = false;
      }
    }

    if (empty) {
      return;
    }

    console.log("out = ", out);

    let resp = await fetchEx(`${this.host}/api/Survey/BaseConfig/Update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        SurveyId: id,
        ...out,
      }),
    });
    console.log("resp?.data = ", resp?.data);
    let extraProps = mapToSurveyExtraProps({
      configProps: resp?.data,
      themeProps: {},
    });
    delete extraProps.themeConfig;
    return extraProps;

    // console.log(resp);
  }

  async updateThemePropertyById(id, props) {
    const propsMap = {
      backgroundColor: "DefBackgroudColor",
      headerImg: "DefHeaderPic",
      mobileHeaderImg: "DefHeaderPhonePic",
      styleType: "StyleType",
      backgroundTheme: "DefTheme",
      headFlag: "HeadFlag",
      fontStyle: "DefFontStyle",
      ExtendColorPalette: "ExtendColorPalette",
    };

    const valueMap = {
      styleType: {
        default: 1,
        customized: 2,
      },
    };

    const out = {};
    let empty = true;
    for (const key in props) {
      const target = propsMap[key];
      const value = valueMap[key] ? valueMap[key][props[key]] : props[key];

      if (target) {
        out[target] = value;
        empty = false;
      }
    }

    if (empty) {
      return;
    }

    const resp = await fetchEx(`${this.host}/api/Survey/Style/Update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        SurveyId: id,
        ...out,
      }),
    });

    let extraProps = mapToSurveyExtraProps({
      configProps: {},
      themeProps: resp?.data,
    });

    return extraProps;
  }

  async updateThemePropertyByIdAndReturnSurveyModel(id, props) {
    const propsMap = {
      backgroundColor: "DefBackgroudColor",
      headerImg: "DefHeaderPic",
      mobileHeaderImg: "DefHeaderPhonePic",
      styleType: "StyleType",
      backgroundTheme: "DefTheme",
      headFlag: "HeadFlag",
      fontStyle: "DefFontStyle",
    };

    const valueMap = {
      styleType: {
        default: 1,
        customized: 2,
      },
    };

    const out = {};
    let empty = true;
    for (const key in props) {
      const target = propsMap[key];
      const value = valueMap[key] ? valueMap[key][props[key]] : props[key];

      if (target) {
        out[target] = value;
        empty = false;
      }
    }

    if (empty) {
      return;
    }

    const resp = await fetchEx(`${this.host}/api/Survey/Style/Update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        SurveyId: id,
        ...out,
      }),
    });

    let extraProps = mapToSurveyExtraProps({
      configProps: {},
      themeProps: resp?.data,
    });

    return extraProps;
  }

  async createQuestionById(id, questionType, pageNo) {
    const TypeMap = {
      SINGLE_CHOICE: 1,
      MULTIPLE_CHOICE: 2,
      FILL_IN: 3,
      MATRIX: 4,
      BASIC_INFO: 5,
      TEXT: 10,
      IMAGE: 11,
      VIDEO: 12,
      PAGE: 13,
      NUMERICAL: 6,
    };

    const PathMap = {
      SINGLE_CHOICE: "/api/Survey/Question/Singal/Insert",
      MULTIPLE_CHOICE: "/api/Survey/Question/Multi/Insert",
      FILL_IN: "/api/Survey/Question/Blank/Insert",
      MATRIX: "/api/Survey/Question/Matrix/Insert",
      BASIC_INFO: "/api/Survey/Question/BaseInfo/Insert",
      TEXT: "/api/Survey/Question/Text/Insert",
      IMAGE: "/api/Survey/Question/Picture/Insert",
      VIDEO: "/api/Survey/Question/Film/Insert",
      PAGE: "/api/Survey/Question/PageLine/Insert",
      NUMERICAL: "/api/Survey/Question/Common/Insert",
    };

    return await fetchEx(`${this.host}${PathMap[questionType]}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + windowGlobal.localStorage.token,
      },
      body: JSON.stringify({
        SurveyId: id,
        surveyId: id,
        questionType: TypeMap[questionType],
        QuestionType: TypeMap[questionType],
        pageNo,
        PageNo: pageNo,
      }),
    });
  }

  async deleteQuestionById(id, questionInst) {
    const PathMap = {
      SINGLE_CHOICE: "/api/Survey/Question/Singal/Delete",
      MULTIPLE_CHOICE: "/api/Survey/Question/Multi/Delete",
      FILL_IN: "/api/Survey/Question/Blank/Delete",
      MATRIX: "/api/Survey/Question/Matrix/Delete",
      BASIC_INFO: "/api/Survey/Question/BaseInfo/Delete",
      TEXT: "/api/Survey/Question/Text/Delete",
      IMAGE: "/api/Survey/Question/Picture/Delete",
      VIDEO: "/api/Survey/Question/Film/Delete",
      PAGE: "/api/Survey/Question/PageLine/Delete",
      NUMERICAL: "/api/Survey/Question/Common/Delete",
    };

    await fetchEx(
      `${this.host}${PathMap[questionInst.type] ||
        PathMap.BASIC_INFO}?surveyId=${id}&questionId=${questionInst.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );
  }

  async updateQuestionById(id, questionInst, nextQuestion, ovProps = {}) {
    if (
      questionInst.type === "SINGLE_CHOICE" ||
      questionInst.type === "MULTIPLE_CHOICE"
    ) {
      const { content, description } = nextQuestion;

      const q = questionInst._data._raw;

      const data = {
        SurveyId: id,
        QuestionId: ovProps.qId || q.QuestionId,
        QuestionSeq: ovProps.qSeq || q.QuestionSeq,
        QuestionType: q.QuestionType,
        IsRequired: nextQuestion.required,
        PageNo: q.PageNo,
        Advance: mapAdvanceToQuestionData(nextQuestion),
        ...getOtherObjData(nextQuestion),
      };

      data.Main = {
        QuestionSubject: JSON.stringify(content),
        QuestionImage: nextQuestion.contentImage,
        QuestionVideo: nextQuestion.contentVideo,
        SubjectStyle: q.SubjectStyle,
        QuestionNote: description,
        Option: mergeUpdatedOptions({
          ours: nextQuestion.options,
          theirs: q.option,
          qType: questionInst.type,
        }),
        Other: getOtherObjData(nextQuestion), // FIXME: also add in data.Main to prevent OtherIsShowText bug, strange api...
      };

      const apiPath =
        questionInst.type === "SINGLE_CHOICE"
          ? "/api/Survey/Question/Singal/Update"
          : "/api/Survey/Question/Multi/Update";

      await fetchEx(`${this.host}${apiPath}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      });
    } else if (questionInst.type === "VIDEO" || questionInst.type === "IMAGE") {
      const { url } = nextQuestion;

      const q = questionInst._data._raw;

      const data = {
        SurveyId: id,
        QuestionId: ovProps.qId || q.QuestionId,
        QuestionSeq: ovProps.qSeq || q.QuestionSeq,
        QuestionType: q.QuestionType,
        IsRequired: q.IsRequired,
        PageNo: q.PageNo,
        QuestionSubject: "",
        SubjectStyle: url,
      };

      const apiPath =
        questionInst.type === "VIDEO"
          ? "/api/Survey/Question/Film/Update"
          : "/api/Survey/Question/Picture/Update";

      await fetchEx(`${this.host}${apiPath}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      });
    } else if (questionInst.type === "TEXT") {
      const { content } = nextQuestion;

      const q = questionInst._data._raw;

      const data = {
        SurveyId: id,
        QuestionId: ovProps.qId || q.QuestionId,
        QuestionSeq: ovProps.qSeq || q.QuestionSeq,
        QuestionType: q.QuestionType,
        IsRequired: q.IsRequired,
        PageNo: q.PageNo,
        QuestionSubject: JSON.stringify(content),
      };

      await fetchEx(`${this.host}/api/Survey/Question/Text/Update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      });
    } else if (questionInst.type === "FILL_IN") {
      const {
        content,
        description,
        answerPlaceholder,
        validateAnswer,
      } = nextQuestion;

      const q = questionInst._data._raw;

      const getValidateAnswerToDataObj = validateAnswer => {
        let { type, from, to } = validateAnswer;
        const typeMap = {
          none: 1,
          text: 2,
          number: 3,
        };
        return {
          BlankValidType: typeMap[type] || 1,
          BlankMinLimit: from,
          BlankMaxLimit: to,
        };
      };

      const data = {
        SurveyId: id,
        QuestionId: ovProps.qId || q.QuestionId,
        QuestionSeq: ovProps.qSeq || q.QuestionSeq,
        QuestionType: q.QuestionType,
        IsRequired: nextQuestion.required,
        PageNo: q.PageNo,
        Main: {
          QuestionSubject: JSON.stringify(content),
          QuestionNote: description,
          BlankDefaultWords: answerPlaceholder,
          QuestionImage: nextQuestion.contentImage,
          QuestionVideo: nextQuestion.contentVideo,
          ...getValidateAnswerToDataObj(validateAnswer),
        },
      };

      await fetchEx(`${this.host}/api/Survey/Question/Blank/Update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      });
    } else if (questionInst.type === "MATRIX") {
      const { content, description, cols, rows } = nextQuestion;

      const q = questionInst._data._raw;

      const mergeOptions = mergeUpdatedOptions({
        // ours: rows.map(row => ({
        //   value: row,
        // })),
        ours: cols.map(col => {
          console.log("q = ", q.option);

          let data = {
            value: col,
          };
          if (q.option !== undefined && q.option !== null) {
            let find = q.option.find(qobj => qobj.OptionContent === col);
            console.log("find = ", find);
            if (find !== undefined && find !== null) {
              data.id = find.OptionId;
            }
          }

          return data;
        }),
        theirs: q.option,
        qType: questionInst.type,
      });

      const data = {
        SurveyId: id,
        QuestionId: ovProps.qId || q.QuestionId,
        QuestionSeq: ovProps.qSeq || q.QuestionSeq,
        QuestionType: q.QuestionType,
        IsRequired: nextQuestion.required,
        PageNo: q.PageNo,
        Main: {
          QuestionSubject: JSON.stringify(content),
          QuestionImage: nextQuestion.contentImage,
          QuestionVideo: nextQuestion.contentVideo,
          QuestionNote: description,
          MatrixItems: rows.join(";"),
          //MatrixItems: rows.reduce((acc, item) => `${acc};${item}`, ""),
          // MatrixItems: cols.reduce((acc, item) => `${acc};${item}`, ""),
          Option: mergeOptions,
          Other: getOtherObjData(nextQuestion),
        },
      };

      await fetchEx(`${this.host}/api/Survey/Question/Matrix/Update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      });
    } else if (questionInst.type === "BASIC_INFO") {
      const { content, description } = nextQuestion;

      const q = questionInst._data._raw;

      const data = {
        SurveyId: id,
        QuestionId: ovProps.qId || q.QuestionId,
        QuestionSeq: ovProps.qSeq || q.QuestionSeq,
        QuestionType: q.QuestionType,
        PageNo: q.PageNo,
        IsRequired: nextQuestion.required,
        QuestionSubject: JSON.stringify(content),
        QuestionImage: nextQuestion.contentImage,
        QuestionVideo: nextQuestion.contentVideo,
        QuestionNote: description,
        BaseDataValidType: mapBasicInfoValidTypeToQuestionData(
          nextQuestion.infoType
        ),
      };

      await fetchEx(`${this.host}/api/Survey/Question/BaseInfo/Update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      });
    } else if (questionInst.type === "NUMERICAL") {
      const { content, description } = nextQuestion;
      const q = questionInst._data._raw;

      const data = {
        SurveyId: id,
        QuestionId: ovProps.qId || q.QuestionId,
        QuestionSeq: ovProps.qSeq || q.QuestionSeq,
        QuestionType: q.QuestionType,
        IsRequired: nextQuestion.required,
        PageNo: q.PageNo,
        // Advance: mapAdvanceToQuestionData(nextQuestion),
        ...getOtherObjData(nextQuestion),
      };

      data.Main = {
        QuestionSubject: JSON.stringify(content),
        QuestionImage: nextQuestion.contentImage,
        QuestionVideo: nextQuestion.contentVideo,
        SubjectStyle: q.SubjectStyle,
        QuestionNote: description,
        NumericalStyle: nextQuestion.numericalStyle,
        NumericalTotal: nextQuestion.numericalTotal,
        DecimalPlace: nextQuestion.decimalPlace,
        Unit: nextQuestion.unit,
        Option: mergeUpdatedOptions({
          ours: nextQuestion.options,
          theirs: q.option,
          qType: questionInst.type,
        }),
      };

      await fetchEx(`${this.host}/api/Survey/Question/Common/Update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      });
    }
  }

  async reorderQuestionById(id, nextQuestions) {
    nextQuestions = nextQuestions.slice(1).map((q, idx) => {
      return {
        QuestionId: q.id,
        QuestionSeq: idx + 1,
      };
    });

    if (nextQuestions.length === 0) {
      return;
    }

    await fetchEx(`${this.host}/api/Survey/Question/Sort`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        SurveyId: id,
        QuestionLists: nextQuestions,
      }),
    });
  }

  async reorderQuestionForCopyById(id, nextQuestions) {
    nextQuestions = nextQuestions.map((q, idx) => {
      return {
        QuestionId: q.QuestionId,
        QuestionSeq: idx + 1,
      };
    });

    if (nextQuestions.length === 0) {
      return;
    }

    await fetchEx(`${this.host}/api/Survey/Question/Sort`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        SurveyId: id,
        QuestionLists: nextQuestions,
      }),
    });
  }

  async fetchStatisticsData(id, signal) {
    const result = {};
    result.live = await fetchEx(
      `${this.host}/api/Analytics/Query?SurveyId=${id}&Env=2`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      },
      signal
    );
    result.live = result.live && result.live.data;
    result.test = await fetchEx(
      `${this.host}/api/Analytics/Query?SurveyId=${id}&Env=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      },
      signal
    );
    result.test = result.test && result.test.data;
    return result;
  }

  //fetch collection api

  async fetchCollectionContactList(id) {
    let resp = null;
    resp = await fetchEx(
      `${this.host}/api/Survey/Question/CollectionWay/ContactList?SurveyId=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );
    if (resp && resp.data && resp.data.List) {
      return resp.data.List;
    }
    return resp;
  }

  async fetchCollectionList(id) {
    let resp = null;
    resp = await fetchEx(
      `${this.host}/api/Survey/Question/CollectionWay/Query?SurveyId=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );

    if (resp && resp.data && Array.isArray(resp.data)) {
      let list = [];
      let accessList = await this.fetchCollectionContactList(id);
      if (accessList !== null) {
        accessList = accessList.map(mapToAccessList);
      } else {
        accessList = [];
      }
      resp.data.forEach(element => {
        element.accessList = accessList;
        list.push(element);
      });
      list = list.map(mapToCollectionList); //return data.list MockData.js
      //[{},{}]
      return list;
    }
  }

  async createCollection(id, provideType) {
    const data = {
      SurveyId: id,
      ProvideType: ProvideType[provideType],
      FinalUrl: `${constants.SURVEY_DOMAIN}/q/?id=${id}&ProvideType=${ProvideType[provideType]}`,
      TestUrl: `${constants.SURVEY_DOMAIN}/q/?id=${id}&ProvideType=${ProvideType[provideType]}&test=true`,
      // FinalUrl: `https://telesurvey.cht.com.tw/q?id=${id}&ProvideType=${ProvideType[provideType]}`,
      // TestUrl: `https://telesurvey.cht.com.tw/q?id=${id}&ProvideType=${ProvideType[provideType]}&test=true`,
      ValidRegister: 1,
      MultiProvideType: 1,
      // ReplyMaxNum: 1000,
      // FullEndFlag: 1,
      // ValidField: 5
    };
    await fetchEx(`${this.host}/api/Survey/Question/CollectionWay/Insert`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + windowGlobal.localStorage.token,
      },
      body: JSON.stringify(data),
    });
  }

  async updateCollection(id, collectionObject) {
    const data = {
      SurveyId: id,
      ProvideType: collectionObject.ProvideType,
      ReplyMaxNum: collectionObject.upperBound
        ? collectionObject.upperBound
        : 0,
      ValidRegister: LoginType[collectionObject.loginType],
      ValidField: ValidField[collectionObject.validateField],
      MultiProvideType:
        duplicateMechanismType[collectionObject.duplicateMechanism],
      FinalUrl: collectionObject.FinalUrl,
      TestUrl: collectionObject.TestUrl,
      FullEndFlag: 0,
      LimitType: collectionObject.limitType,
    };
    await fetchEx(`${this.host}/api/Survey/Question/CollectionWay/Update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    });
  }

  async deleteCollection(id, provideType) {
    await fetchEx(
      `${this.host}/api/Survey/Question/CollectionWay/Delete?SurveyId=${id}&ProvideType=${provideType}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );
  }

  /** 取得交叉選項回覆數量限制 資料 */
  async fetchLimitData(surveyId, provideType) {
    let resp = null;
    resp = await fetchEx(
      `${this.host}/api/Survey/QuestionLimit/Query?surveyId=${surveyId}&provideType=${provideType}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );

    return resp;
  }

  /** 更新 交叉選項回覆數量限制 資料 */
  async updateLimitData(surveyId, provideType, data) {
    let resp = null;
    resp = await fetchEx(
      `${this.host}/api/Survey/QuestionLimit/Update?surveyId=${surveyId}&provideType=${provideType}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      }
    );

    return resp;
  }

  /** 刪除 交叉選項回覆數量限制 資料 */
  async deleteLimitData(surveyId, provideType) {
    let resp = null;
    resp = await fetchEx(
      `${this.host}/api/Survey/QuestionLimit/Delete?surveyId=${surveyId}&provideType=${provideType}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );

    return resp;
  }

  /** 取得交叉選項回覆數量限制 分析資料 (驗證預覽Token) */
  async fetchLimitStatisticData(surveyId, provideType, test) {
    let resp = null;
    resp = await fetchEx(
      `${this.host}/api/Survey/QuestionLimit/Statistic?surveyId=${surveyId}&provideType=${provideType}&test=${test}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );

    return resp;
  }

  /** 取得交叉選項回覆數量限制 分析資料 (驗證填寫Token)*/
  async fetchOutSideLimitStatisticData(surveyId, provideType, test) {
    let resp = null;
    resp = await fetchEx(
      `${this.host}/api/Survey/QuestionLimit/OutSideSurveyStatistic?surveyId=${surveyId}&provideType=${provideType}&test=${test}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );

    return resp;
  }

  async surveySignOff(id) {
    await fetchEx(`${this.host}/api/Survey/Question/CollectionWay/Proposal`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        SurveyId: id,
      }),
    });
  }
  //fetch logicRule api
  async fetchLogicList(id) {
    if (
      typeof windowGlobal.localStorage.getItem("token") == "undefined" ||
      windowGlobal.localStorage.getItem("token") == null
    ) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("verifyInfo");
      window.alert("您使用的是無效Token，系統將自動為您登出。");
      window.location.replace("/");
      return;
    }

    let resp = null;

    resp = await fetchEx(`${this.host}/api/Survey/Logic/Query?surveyId=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
    });
    // console.log("resp:", resp);
    if (resp && resp.data && resp.data.LogicList) {
      return resp.data.LogicList;
    } else {
      return [];
    }
  }

  async insertLogicRule(data) {
    if (
      typeof windowGlobal.localStorage.getItem("token") == "undefined" ||
      windowGlobal.localStorage.getItem("token") == null
    ) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("verifyInfo");
      window.alert("您使用的是無效Token，系統將自動為您登出。");
      window.location.replace("/");
      return;
    }

    // let createData = {
    //   SurveyId: id,
    //   LogicList: [data]
    // }
    // console.log('insertLogicRule createData',createData)
    const resp = await fetch(`${this.host}/api/Survey/Logic/Insert`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + windowGlobal.localStorage.token,
      },
      body: JSON.stringify(data),
    });
    // console.log("insertLogicRule resp:", resp);
    return resp;
  }

  async updateLogicRule(data) {
    if (
      typeof windowGlobal.localStorage.getItem("token") == "undefined" ||
      windowGlobal.localStorage.getItem("token") == null
    ) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("verifyInfo");
      window.alert("您使用的是無效Token，系統將自動為您登出。");
      window.location.replace("/");
      return;
    }
    // let createData = {
    //   SurveyId: id,
    //   LogicList: [data]
    // }
    // console.log('insertLogicRule createData',createData)
    const resp = await fetch(`${this.host}/api/Survey/Logic/Update`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    });
    // console.log("insertLogicRule resp:", resp);
    return resp;
  }

  async removeLogicRule(id, logicRuleId) {
    if (
      typeof windowGlobal.localStorage.getItem("token") == "undefined" ||
      windowGlobal.localStorage.getItem("token") == null
    ) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("verifyInfo");
      window.alert("您使用的是無效Token，系統將自動為您登出。");
      window.location.replace("/");
      return;
    }
    const resp = await fetch(
      `${this.host}/api/Survey/Logic/Delete?SurveyId=${id}&LogicId=${logicRuleId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(data),
      }
    );
    // console.log("insertLogicRule resp:", resp);
    return resp;
  }

  async fetchEndPage(id) {
    const json = await fetchEx(
      `${this.host}/api/Survey/EndPage/Query?surveyId=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );
    let resp = json && json.data ? json.data : null;
    return resp;
  }

  async fetchEndingPageById(id) {
    const json = await fetchEx(
      `${this.host}/api/Survey/EndPage/Query?SurveyId=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );
    let resp = json && json.data ? json.data : null;
    return resp;
  }

  // // 2022-08-09 ALVIN 歡迎頁APIs: welcome page
  async fetchWelcomePage(id) {
    const json = await fetchEx(
      `${this.host}/api/Survey/WelcomePage/Query?surveyId=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );
    let resp = json && json.data ? json.data : null;
    return resp;
  }

  async fetchWelcomePageById(id) {
    const json = await fetchEx(
      `${this.host}/api/Survey/WelcomePage/Query?SurveyId=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );
    let resp = json && json.data ? json.data : null;
    return resp;
  }

  //外部問卷登入取得Token
  async getExternalUserToken(surveyId, surveyEnv, provideType, p) {
    const resp = await fetchEx(
      `${this.host}/api/OutsideSurvey/Login?SurveyId=${surveyId}&Env=${surveyEnv}&ProvideType=${provideType}&p=${p}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );
    let data = {};
    if (resp && resp.data) {
      data = resp.data;
    }

    // console.log("data", data);
    return data;
  }

  //資格驗證
  async externalLoginVerify(
    surveyId,
    provideType,
    validField,
    validData,
    accessToken
  ) {
    const resp = await fetchEx(`${this.host}/api/OutsideSurvey/LoginVerify`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        SurveyId: surveyId,
        ProvideType: provideType,
        ValidField: validField,
        ValidData: validData,
      }),
    });
    return resp;
  }

  //外部問券生成
  async fetchExternalSurvey(id, OtherFlag) {
    let resp = null;
    resp = await fetchEx(
      `${this.host}/api/OutsideSurvey/RenderSurvey?SurveyId=${id}&OtherFlag=${OtherFlag}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );

    if (resp.code === "200") {
      let questions = resp.data.QuestionList
        ? resp.data.QuestionList.filter(q => !!q.QuestionId).sort(
            sortByQuestionPageAndSeq
          )
        : [];

      questions = insertPageIntoQuestions(questions).map(mapToQuestion);

      let theme = {
        SurveyId: resp.data.SurveyId,
        StyleType: resp.data.StyleType,
        DefHeaderPic: resp.data.DefHeaderPic,
        DefBackgroudColor: resp.data.DefBackgroudColor,
        DefHeaderPhonePic: resp.data.DefHeaderPhonePic,

        // 2022-05-27 : 企業版型
        DefTheme: null,
        HeadFlag: resp.data.HeadFlag,
        DefFontStyle: resp.data.Font,
        Theme: resp.data.Theme,
      };

      let extraProps = mapToSurveyExtraProps({
        configProps: resp.data.SurveySetting,
        themeProps: theme,
      });

      let data = {
        code: "200",
        survey: {
          id: resp.data.SurveyId,
          title: resp.data.Title,
          questions,
          ...extraProps,
          _configProps: resp.data.SurveySetting,
          _themeProps: theme,
        },
        logicList: resp.data.LogicList || [],
        endPage: {
          ...resp.data.EndPage,
          SurveyId: resp.data.SurveyId,
        },
        welcomePage: {
          ...resp.data.WelcomePage,
          SurveryId: resp.data.SurveyId,
          Title: resp.data.Title,
        },
      };

      return data;
    }

    // console.log("fetchExternalSurvey resp code not 200::::", data);
    return resp;
  }

  // 取得預覽範本
  async fetchTemplateSurvey(id, OtherFlag) {
    let resp = null;
    resp = await fetchEx(
      `${this.host}/api/Survey/Template/RenderSurvey?TemplateId=${id}&OtherFlag=${OtherFlag}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );

    if (resp.code === "200") {
      let questions = resp.data.QuestionList
        ? resp.data.QuestionList.filter(q => !!q.QuestionId).sort(
            sortByQuestionPageAndSeq
          )
        : [];

      questions = insertPageIntoQuestions(questions).map(mapToQuestion);

      let theme = {
        SurveyId: resp.data.SurveyId,
        StyleType: resp.data.StyleType,
        DefHeaderPic: resp.data.DefHeaderPic,
        DefBackgroudColor: resp.data.DefBackgroudColor,
        DefHeaderPhonePic: resp.data.DefHeaderPhonePic,

        // 2022-05-27 : 企業版型
        DefTheme: null,
        HeadFlag: resp.data.HeadFlag,
        DefFontStyle: resp.data.Font,
        Theme: resp.data.Theme,
      };

      let extraProps = mapToSurveyExtraProps({
        configProps: resp.data.SurveySetting,
        themeProps: theme,
      });

      let data = {
        code: "200",
        survey: {
          id: resp.data.SurveyId,
          title: resp.data.Title,
          questions,
          ...extraProps,
          _configProps: resp.data.SurveySetting,
          _themeProps: theme,
        },
        logicList: resp.data.LogicList || [],
        endPage: {
          ...resp.data.EndPage,
          SurveyId: resp.data.SurveyId,
        },
      };

      return data;
    }

    // console.log("fetchExternalSurvey resp code not 200::::", data);
    return resp;
  }

  async createEndPage(props) {
    const resp = await fetchEx(`${this.host}/api/Survey/EndPage/Add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + windowGlobal.localStorage.token,
      },
      body: JSON.stringify(props),
    });
    return resp;
  }

  async editEndPage(props) {
    const resp = await fetchEx(`${this.host}/api/Survey/EndPage/Edit`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
      body: JSON.stringify(props),
    });
    return resp;
    // const resp = await fetch(`${this.host}/api/Survey/EndPage/Edit`, {
    //   method: "PUT",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(props),
    // });
    // return resp;
  }

  // 2022-08-09 ALVIN 歡迎頁APIs: create/edit
  async createWelcomePage(props) {
    const resp = await fetchEx(`${this.host}/api/Survey/WelcomePage/Add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + windowGlobal.localStorage.token,
      },
      body: JSON.stringify(props),
    });
    return resp;
  }

  async editWelcomePage(props) {
    const resp = await fetchEx(`${this.host}/api/Survey/WelcomePage/Edit`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
      body: JSON.stringify(props),
    });
    return resp;
    // const resp = await fetch(`${this.host}/api/Survey/EndPage/Edit`, {
    //   method: "PUT",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(props),
    // });
    // return resp;
  }
  async fetchAccountListNonAdmin() {
    let url = `${this.host}/api/Survey/Account/List`;
    const json = await fetchEx(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
    });
    json.data.forEach(u => {
      u.userid = u.UserId;
      u.name = u.UserName;
    });
    return json.data;
  }
  async fetchAccountList(
    token,
    page = 0,
    perPage = 10,
    filter = null,
    query = null
  ) {
    let url = `${this.host}/api/Survey/Account/queryByPage`;
    let connector = url.indexOf("?") >= 0 ? "&" : "?";
    if (page !== 0) {
      url += `${connector}page=${page}`;
    }
    connector = url.indexOf("?") >= 0 ? "&" : "?";
    if (perPage !== 0) {
      url += `${connector}perPage=${perPage}`;
    }
    if (filter != null && filter.length !== 0) {
      connector = url.indexOf("?") >= 0 ? "&" : "?";
      url += `${connector}search=${filter}`;
    }
    if (query != null) {
      const keys = Object.keys(query);
      console.log("QUERY", query);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        console.log(keys, query[key]);
        if (query[key] === undefined || query[key] === null) continue;

        let connector = url.indexOf("?") >= 0 ? "&" : "?";
        url += `${connector}${key}=${query[key]}`;
      }
    }
    const json = await fetchEx(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
    });
    mapAccountList(json.data);

    return {
      data: json.data,
      pagination: json.pagination,
      filters: json.filters,
    };
  }

  async submitAccount(data) {
    const resp = await fetchEx(`${this.host}/api/Survey/Account/Submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + windowGlobal.localStorage.token,
      },
      body: JSON.stringify(data),
    });
    return resp;
  }

  async fetchTeamList() {
    const json = await fetchEx(`${this.host}/api/Survey/Team/queryByPage`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
    });
    mapTeamList(json.data);

    return json.data;
  }

  async fetchMyTeamList(userCode) {
    const json = await fetchEx(
      `${this.host}/api/Survey/Team/queryByPage?userCode=${userCode}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );
    mapTeamList(json.data);

    return json.data;
  }

  async fetchTeamUsers(teamId) {
    const json = await fetchEx(
      `${this.host}/api/Survey/Team/QueryUsers?teamId=${teamId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );
    mapTeamUserList(json.data);

    return json.data;
  }

  async queryTeamById(id) {
    const json = await fetchEx(
      `${this.host}/api/Survey/Team/Query?teamId=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );
    // mapTeamList(json.data);

    return json.data;
  }

  async submitTeam(data) {
    const resp = await fetchEx(`${this.host}/api/Survey/Team/Submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + windowGlobal.localStorage.token,
      },
      body: JSON.stringify(data),
    });
    return resp;
  }

  async submitTeamSurveys(data) {
    const resp = await fetchEx(`${this.host}/api/Survey/Team/SubmitSurveys`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + windowGlobal.localStorage.token,
      },
      body: JSON.stringify(data),
    });
    return resp;
  }

  async submitTeamUsers(data) {
    const resp = await fetchEx(`${this.host}/api/Survey/Team/SubmitUsers`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + windowGlobal.localStorage.token,
      },
      body: JSON.stringify(data),
    });
    return resp;
  }

  async submitTeamOwner(data) {
    const resp = await fetchEx(`${this.host}/api/Survey/Team/SubmitOwner`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + windowGlobal.localStorage.token,
      },
      body: JSON.stringify(data),
    });
    return resp;
  }

  async deleteTeam(teamId) {
    const resp = await fetch(
      `${this.host}/api/Survey/Team/Delete?teamId=${teamId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );
    return resp;
  }

  async fetchTemplateList() {
    const json = await fetchEx(`${this.host}/api/Survey/Template/List`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
    });
    let resp = null;
    resp = json && json;
    return resp;
  }

  // 取得範本資料依照ID
  async fetchTemplateDataById(id) {
    const json = await fetchEx(
      `${this.host}/api/Survey/Template/TemplateData/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );
    let resp = null;
    resp = json && json;
    return resp;
  }

  // 取得範本類別列表對應的範本
  async fetchTemplateByCategory(category) {
    const json = await fetchEx(
      `${this.host}/api/Survey/Template/Category/List/${category}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );
    let resp = null;
    resp = json && json;
    return resp;
  }

  async copyFromTemplate(templateId, surveyId) {
    console.log("payload = ", surveyId);
    const resp = await fetchEx(`${this.host}/api/Survey/Template/Apply`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + windowGlobal.localStorage.token,
      },
      body: JSON.stringify({
        SurveyId: surveyId,
        TemplateId: templateId,
      }),
    });
    return resp;
  }

  async deleteTemplate(templateId) {
    const resp = await fetch(
      `${this.host}/api/Survey/Template/Delete?templateId=${templateId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );
    return resp;
  }

  // 更新範本
  async updateTemplate(id, props) {
    const resp = await fetch(`${this.host}/api/Survey/Template/Update/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ ...props }),
    });
    return resp;
  }

  async saveToMyTemplate(surveyId, title) {
    const resp = await fetchEx(`${this.host}/api/Survey/Template/Add`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + windowGlobal.localStorage.token,
      },
      body: JSON.stringify({
        SurveyId: surveyId,
        Title: title,
      }),
    });
    return resp;
  }

  // 新增至共用範本
  async saveToPublicTemplate(surveyId, title, templateCategory) {
    const resp = await fetchEx(`${this.host}/api/Survey/Template/AddPublic`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + windowGlobal.localStorage.token,
      },
      body: JSON.stringify({
        SurveyId: surveyId,
        Title: title,
        TemplateCategory: templateCategory,
      }),
    });
    return resp;
  }

  async sendCopyToUser(surveyId, userId) {
    const resp = await fetchEx(`${this.host}/api/Survey/Template/Share`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + windowGlobal.localStorage.token,
      },
      body: JSON.stringify({
        SurveyId: surveyId,
        UserId: userId,
      }),
    });
    return resp;
  }

  async submitAnswer(data) {
    const resp = await fetchEx(`${this.host}/api/OutsideSurvey/Submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + windowGlobal.localStorage.token,
      },
      body: JSON.stringify(data),
    });
    return resp;
  }

  // 個資同意api
  async submitPrivacy(data) {
    const resp = await fetchEx(`${this.host}/api/OutsideSurvey/PrivacySubmit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + windowGlobal.localStorage.token,
      },
      body: JSON.stringify(data),
    });
    return resp;
  }

  async logOutWithToken(account, token) {
    const resp = await fetchEx(`${this.host}/api/System/LogOut`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        UserCode: account,
      }),
    });
    return resp;
  }

  async logOut(account) {
    const resp = await fetchEx(`${this.host}/api/System/LogOut`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + windowGlobal.localStorage.getItem("token"),
      },
      body: JSON.stringify({
        UserCode: account,
      }),
    });
    return resp;
  }

  async verifyUser(account, phone) {
    let resp = await fetchEx(`${this.host}/api/SendOTP`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + windowGlobal.localStorage.token,
      },
      body: JSON.stringify({
        UserCode: account,
        pwd: phone,
        mode: 1, // reset mode
      }),
    });
    return resp;
  }

  async changePassword(userId, password) {
    const resp = await fetchEx(`${this.host}/api/system/ChangePass`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + windowGlobal.localStorage.token,
      },
      body: JSON.stringify({
        UserId: userId,
        Password: password,
      }),
    });
    return resp;
  }

  // 選項類 - 企業主題選單
  async fetchThemeList() {
    let resp = null;
    resp = await fetchEx(`${this.host}/api/Survey/Option/Theme/List`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
    });
    return resp;
  }
  // 選項類 - 數字總和題單位選單
  async fetchUnitList() {
    let resp = null;
    resp = await fetchEx(`${this.host}/api/Survey/Option/Unit/List`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
    });
    return resp;
  }
  // 選項類 - 字型選單
  async fetchFontList() {
    let resp = null;
    resp = await fetchEx(`${this.host}/api/Survey/Option/Font/List`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
    });
    return resp;
  }

  // 選項類 - 範本選單
  async fetchTemplateCategory() {
    let resp = null;
    resp = await fetchEx(
      `${this.host}/api/Survey/Option/TemplateCategory/List`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );
    return resp;
  }

  // 設定類 - 取得取得編輯時間限制
  async fetchEditTimeLimit() {
    let resp = null;
    resp = await fetchEx(`${this.host}/api/Survey/Option/EditTimeLimit/List`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
    });
    return resp;
  }

  // 選項類 - 範本選單
  async fetchLock(surveyId) {
    let resp = null;
    resp = await fetchEx(`${this.host}/api/Survey/Lock?surveyId=${surveyId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
    });
    return resp;
  }

  // 選項類 - 共用範本類別
  async fetchUnlock(surveyId) {
    let resp = null;
    resp = await fetchEx(`${this.host}/api/Survey/Lock?surveyId=${surveyId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
      },
    });
    return resp;
  }
  // 個資資料
  async queryPrivacyList(token) {
    if (
      typeof windowGlobal.localStorage.getItem("token") == "undefined" ||
      windowGlobal.localStorage.getItem("token") == null
    ) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("verifyInfo");
      window.alert("您使用的是無效Token，系統將自動為您登出。");
      window.location.replace("/");
      return;
    }

    const json = await fetchEx(
      `${this.host}/api/Survey/PrivacyContent/queryByPage`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );

    let resp = null;
    if (json && json.data) {
      resp = json.data.map(mapToPrivacy);
    }
    return resp;
  }
  // 設定啓用
  async enablePrivacy(privacyId) {
    if (
      typeof windowGlobal.localStorage.getItem("token") == "undefined" ||
      windowGlobal.localStorage.getItem("token") == null
    ) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("verifyInfo");
      window.alert("您使用的是無效Token，系統將自動為您登出。");
      window.location.replace("/");
      return;
    }

    const payload = {
      Enable: 1,
      PrivacyId: privacyId,
    };

    const json = await fetchEx(
      `${this.host}/api/Survey/PrivacyContent/setEnable`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
        body: JSON.stringify(payload),
      }
    );

    let resp = json;
    return resp;
  }

  // 刪除版本
  async deletePrivacy(privacyId) {
    if (
      typeof windowGlobal.localStorage.getItem("token") == "undefined" ||
      windowGlobal.localStorage.getItem("token") == null
    ) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("verifyInfo");
      window.alert("您使用的是無效Token，系統將自動為您登出。");
      window.location.replace("/");
      return;
    }

    const json = await fetchEx(
      `${this.host}/api/Survey/PrivacyContent/Delete?privacyId=${privacyId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );

    let resp = json;
    return resp;
  }

  // 複製版本
  async clonePrivacy(privacyId, title) {
    if (
      typeof windowGlobal.localStorage.getItem("token") == "undefined" ||
      windowGlobal.localStorage.getItem("token") == null
    ) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("verifyInfo");
      window.alert("您使用的是無效Token，系統將自動為您登出。");
      window.location.replace("/");
      return;
    }

    const json = await fetchEx(
      `${this.host}/api/Survey/PrivacyContent/Clone?privacyId=${privacyId}&title=${title}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
      }
    );

    let resp = json;
    return resp;
  }

  // 更新版本資訊
  async updatePrivacy(data) {
    if (
      typeof windowGlobal.localStorage.getItem("token") == "undefined" ||
      windowGlobal.localStorage.getItem("token") == null
    ) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("verifyInfo");
      window.alert("您使用的是無效Token，系統將自動為您登出。");
      window.location.replace("/");
      return;
    }

    const json = await fetchEx(
      `${this.host}/api/Survey/PrivacyContent/Update`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${windowGlobal.localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      }
    );

    let resp = json;
    return resp;
  }
}

export { ApiAdapter };
