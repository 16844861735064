/** 主要API設定 */

//const DEV_URL = "http://139.162.67.143:8081";
// const DEV_URL = "http://localhost:8001";
const DEV_URL = "https://survey.chatok.io";

// const DEV_URL = "https://testecrmsurvey.cht.com.tw";

// const DEV_URL = "https://testecrmsurvey.cht.com.tw";
// const DEV_URL = "https://ecrmsurvey.cht.com.tw";
//
// const DEV_URL = "https://testtelesurvey.cht.com.tw";
// const DEV_URL = "https://telesurvey.cht.com.tw";

/** 如果是正式站直接留空，call自己 */
const API_URL = process.env.NODE_ENV === "development" ? DEV_URL : "";

/** 分離設定 */
const hostname = typeof window !== "undefined" && window.location.hostname;
// 填寫站台DOMAIN：後台收集方式會用到
var SURVEY_DOMAIN = hostname;
if (hostname === "ecrmsurvey.cht.com.tw") {
  SURVEY_DOMAIN = "https://telesurvey.cht.com.tw";
}
if (hostname === "testecrmsurvey.cht.com.tw") {
  SURVEY_DOMAIN = "https://testtelesurvey.cht.com.tw";
}
if (hostname === "survey-mgt.chatok.io") {
  SURVEY_DOMAIN = "https://survey.chatok.io";
}

const Settings = {
  env: "stg", //環境變數
  apiUrl: API_URL, //後端api URL
  apiTokenUrl: API_URL, //"http://139.162.67.143:8081",後端api Token URL
  /** 填寫站台DOMAIN */
  SURVEY_DOMAIN,
  debug: true, //是否要啟用debug
  /** useMock：true 使用問卷假資料 */
  useMock: false,
  /** useProxy: true:啟用代理伺服器，啟用後proxyHost生效 */
  useProxy: false, //是否用代理伺服器，啟用後proxyHost生效
  /** proxyHost: 本地開發代理伺服器 */
  proxyHost:
    "https://xzolnm24n6.execute-api.ap-northeast-1.amazonaws.com/dev/proxy",
  /** externalIdleTimerDuration: 外部填寫閒置登出時間 */
  externalIdleTimerDuration: 480 * 60,
  /** internalIdleTimerDuration: 內部問卷閒置登出時間 */
  internalIdleTimerDuration: 30 * 60,
  USER_TYPE: {
    MGT: 1,
    CDP: 2,
  },
  // CRM的問卷沒有此欄位，CDP問卷標註為字串"cdp"
  SURVEY_TYPE: {
    CDP: "cdp",
  },
};

module.exports = Settings;
